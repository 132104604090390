<template>
  <page-header-wrapper style="margin: unset;">
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='month'>
        <a-month-picker v-model="form.month" format="YYYY-MM" :valueFormat="monthFormatType" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <a-button type='primary' icon='plus' @click='initialDeliver1'>新增品牌公司</a-button>
        <span style="margin-left: 30px;"></span>
        <span style="margin-right: 20px;">分公司总业绩合计：{{this.ext1}}</span>
      </div>
      <s-table id="table" ref='table' rowKey='number' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>
        <span slot='brandName' slot-scope='text, record'>
          <template>
<!--            <a @click='handleEdit(record)'>-->
              {{ text || "未知" }}
          </template>
        </span>
        <span slot='brandCount' slot-scope='text, record'>
          <template>
            {{record.areaList.length}}
          </template>
        </span>
        <span slot='companyNames' slot-scope='text, record'>
          <template>
            {{getCompanyStr(record)}}
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space v-if="form.month == moment().format(monthFormatType)">
              <a @click='initialDeliver1(record)'>编辑</a>
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                            @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>

    <a-modal :title='deliver1.form.id ? "编辑品牌公司" : "新增品牌公司"' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading'>
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 8 }' :wrapperCol='{ span: 16 }'>
        <a-form-model-item label='品牌公司名称' prop='brandName'>
          <a-input v-model='deliver1.form.brandName' />
        </a-form-model-item>
        <a-form-model-item label='分公司' prop='areaIdList'>
          <a-select show-search v-model='deliver1.form.areaIdList'
                    :default-active-first-option='false'
                    :filter-option='false'
                    :allowClear='true'
                    mode="multiple"
                    placeholder="请选择"
                    @change="handleChange2"
                    @search='handleSearch2'>
            <a-select-option v-for='item in areaIdListArr' :key='item.id'>
              {{ item.areaName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import {ChartCard, Ellipsis, STable} from '@/components'
import {deleteBrand, deletePerformanceArea, putBrand, queryBrand, queryPerformanceArea} from '@/api/performance'
import moment from 'moment'
import storage from '@/utils/storage.js';

const columns = [
  {
    width: 150,
    title: '品牌公司',
    dataIndex: 'brandName',
    scopedSlots: { customRender: 'brandName' }
  },
  {
    width: 100,
    title: '分公司数量',
    dataIndex: 'brandCount',
    scopedSlots: { customRender: 'brandCount' }
  },
  {
    width: 120,
    title: '分公司',
    dataIndex: 'companyNames',
    scopedSlots: { customRender: 'companyNames' }
  },
  {
    width: 100,
    title: '分公司总业绩',
    dataIndex: 'totalPerformance',
    scopedSlots: { customRender: 'totalPerformance' }
  },
  {
    width: 100,
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const monthFormatType = 'YYYYMM';

export default {
  name: 'PerformanceAreaList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      // 筛选表单
      monthFormatType,
      form: {
        month: moment().format(monthFormatType),
      },
      loading: false,
      dashboard: {}, //订单统计数据
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {},
      ext1:0,//分公司总业绩合计
      loadData: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        let param = Object.assign(parameter, this.queryParam, this.form);
        return queryBrand(param)
          .then(datum => {
            this.ext1 = datum.ext1;
            return datum
          })
      },
      areaIdListArr: [],
      presidentMerchantList: [],
      deliver1: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {
          brandName: [{ required: true, message: '请输入品牌公司名称', trigger: 'change' }],
          areaIdListArr: [{ required: true, message: '请选择分公司', trigger: 'change' }],
        },
      },
    }
  },
  created() {},
  methods: {
    getCompanyStr(record){
        let nameDesc =""
        for(const item of record.areaList){
          nameDesc = nameDesc+item.areaName+",";
        }
        return nameDesc;
    },
    moment,
    handleChange2(e) {
      console.log(e);
      this.$set(this.deliver1.form, "areaIdListArr", e)
    },
    handleSearch2(record) {
      let params = { pageNum: 1, pageSize: 10,selectBrandCode:this.deliver1.form.selectBrandId,month:this.form.month  };
      // if(record){
      //   debugger
      // }
      queryPerformanceArea(params).then(result => {
        this.areaIdListArr = result.records;
        /*下面主要作用就是追加不在集合的项---------模糊搜索，用于日后扩展*/
        // let list = this.deliver1.form.areaList;
        // if (list  && list.length > 0) {
        //   for (const item of list) {
        //     if (this.areaIdList.findIndex(value => item.id == value.id) == -1) {
        //       this.areaIdList.unshift({
        //         id: item.id,
        //         areaName: item.areaName,
        //       })
        //     }
        //   }
        // }
      })
    },
    handleReset() {
      this.form.month = moment().format(monthFormatType);
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    initialDeliver1(record) {
      if(record &&  record.brandCode){
        this.deliver1.form.selectBrandId = record.brandCode;
      }

      this.handleSearch2(record)

      if (record && record.areaList) {
        record.areaIdList = record.areaList.map(item => item.id)
      }
      this.deliver1.form = Object.assign({}, record);
      this.deliver1.loading = false
      this.deliver1.visible = true
    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        console.log(result)
        if (result) {
          this.deliver1.loading = true;
          // this.deliver1.form.areaIdList = this.deliver1.form.areaIdListArr.join(",");
          this.deliver1.form.areaIdList = this.deliver1.form.areaIdListArr;

          let params = Object.assign(this.deliver1.form);
          // debugger;
          putBrand(params).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver1.visible = false
            this.deliver1.loading = false;
          }).catch(() => {
            this.deliver1.loading = false;
          })
        }
      })
    },
    handleEdit(record) {
      let companyNameStr = this.getCompanyStr(record);
      record.companyNameStr = companyNameStr
      storage.set("performanceAreaEdit_" + record.id, record)
      this.$router.push({ path: '/performance/area/company/edit', query: { id: record.id } })
    },
    handleDelete(record) {
      deleteBrand({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
  }
}
</script>
<style lang="less" scoped>
/deep/.total {
  font-size: 20px;
}
/deep/.ant-page-header{
  display: none;
}
</style>
