<template>
  <page-header-wrapper class="month-discount" :title='this.$route.meta.title'>
    <a-tabs default-active-key="1" :tabBarStyle="{background: '#fff'}">
      <a-tab-pane key="1" tab="分公司">
        <list></list>
      </a-tab-pane>
      <a-tab-pane key="2" tab="品牌公司">
        <company></company>
        <!--      <a-tab-pane key="2" :tab="'直付公司订单' + '(' + directTotal + ' 笔)' ">-->
<!--        <direct-list  @setDirectTitle="setDirectTitle"></direct-list>-->
      </a-tab-pane>
    </a-tabs>
  </page-header-wrapper>
</template>

<script>
import List from "./office/List.vue"
import Company from "./company/List.vue"
import moment from "moment";

const formatType = 'YYYY-MM-DD HH:mm';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'MainList',
  components: {
    List,Company
  },
  data() {
    return {
      directTotal: -1,

    }
  },
  created() {
    // this.handleInitial();
  },
  methods: {
    setDirectTitle(value) {
      // this.directTotal = value
    },

    handleInitial(){

      // let arr = this.getDataStartEnd(baseStart);
      // let params = {};
      // params.start = arr[0];
      // params.end = arr[1];
      // queryDirectOrder(params)
      //   .then(datum => {
      //     this.setDirectTitle(datum.total );
      //   })

    },
    // getDataStartEnd(tempMonth){
    //   let start = "";
    //   let end = "";
    //
    //   let monthYear = tempMonth.split("-");
    //   let year = monthYear[0];
    //   let month = monthYear[1];
    //   end = this.getMonthFinalDay(year,month);
    //   start = year + "-" + month + "-" + "01";
    //
    //   end = end + " 23:59";
    //   start = start + " 00:00";
    //
    //   let array = [];
    //   array.push(start);
    //   array.push(end);
    //   return array;
    // },
    // getMonthFinalDay(year,month){
    //   let day='';
    //   if(year == null || year===''){
    //     year = new Date().getFullYear();
    //   }
    //   if(month == null || month===''){
    //     month = new Date().getMonth()+1;
    //   }
    //   day = new Date(new Date(year,month).setDate(0)).getDate();
    //   return year+"-"+month+"-"+day;
    //
    // },

  },
}
</script>
<style lang="less">
.month-discount {
  .ant-tabs-nav-wrap {
    padding: 0 26px;
  }
  .ant-tabs-bar {
    margin: unset !important;
  }
  >.ant-pro-grid-content>.ant-pro-page-header-wrap-children-content {
    margin: unset !important;
  }
}
</style>
