import request from '@/utils/request'

export function queryPerformanceArea(parameter) {
  return request({
    url: '/performance/area/page',
    method: 'get',
    params: parameter
  })
}

export function queryBrand(parameter) {
  return request({
    url: '/performance/brand/page',
    method: 'get',
    params: parameter
  })
}

export function deletePerformanceArea(parameter) {
  return request({
    url: '/performance/area/del/' + parameter.id,
    method: 'delete'
  })
}

export function deleteBrand(parameter) {
  return request({
    url: '/performance/brand/del/' + parameter.id,
    method: 'delete'
  })
}

export function putPerformanceArea(parameter) {
  return request({
    url: '/performance/area/save',
    method: 'post',
    data: parameter
  })
}

//使用积分更新开关
export function putPerformancePermission(parameter) {
  return request({
    url: '/performance/area/save/cash/permission',
    method: 'post',
    data: parameter
  })
}

export function putBrand(parameter) {
  return request({
    url: '/performance/brand/save',
    method: 'post',
    data: parameter
  })
}

export function queryPerformancePresident(parameter) {
  return request({
    url: '/performance/president/page',
    method: 'get',
    params: parameter
  })
}

export function queryPerformanceCenter(parameter) {
  return request({
    url: '/performance/center/page',
    method: 'get',
    params: parameter
  })
}

export function queryPerformanceReward(parameter) {
  return request({
    url: '/performance/president/reward',
    method: 'get',
    params: parameter
  })
}

export function deletePerformanceReward(parameter) {
  return request({
    url: '/performance/president/reward/delete/' + parameter.id,
    method: 'delete'
  })
}

export function updatePerformanceReward(parameter) {
  return request({
    url: '/performance/president/reward/update',
    method: 'post',
    data: parameter
  })
}

export function getLocalUserData(parameter) {
  let urlID = parameter.id;
  delete parameter.id;
  return request({
    url: '/performance/president/structure/'+urlID,
    method: 'get',
    params: parameter
  })
}
