<template>
  <page-header-wrapper style="margin: unset;">
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='month'>
        <a-month-picker v-model="form.month" format="YYYY-MM" :valueFormat="monthFormatType" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <a-button type='primary' icon='plus' @click='initialDeliver1'>分公司构建</a-button>
        <span style="margin-left: 30px;"></span>
        <span style="margin-right: 20px;">可用积分合计：{{this.ext1}}</span>
        <span style="margin-right: 20px;">总业绩合计：{{this.ext2}}</span>
      </div>
      <s-table id="table" ref='table' rowKey='number' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>
        <span slot='brandName' slot-scope='text, record'>
          <template>
            <a @click='handleEdit(record)'>
              {{ text || "未知" }}
            </a>
          </template>
        </span>
        <span slot='virtualNumberCashPermission' slot-scope='text,record'>
          <template>
               <a-switch :checked="record.virtualNumberCashPermission == '1'" @change="isUserCash(record)" />
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space v-if="form.month == moment().format(monthFormatType)">
              <a @click='initialDeliver1(record)'>编辑</a>
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                            @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>

    <a-modal :title='deliver1.form.id ? "分公司编辑" : "分公司构建"' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading'>
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 8 }' :wrapperCol='{ span: 16 }'>

        <a-form-model-item label='分公司名称' prop='areaName'>
          <a-input v-model='deliver1.form.areaName' />
        </a-form-model-item>

        <a-form-model-item label='分公司简称' prop='areaShortName'>
          <a-input v-model='deliver1.form.areaShortName' />
        </a-form-model-item>

        <a-form-model-item label='法人代表' prop='legal'>
          <a-input v-model='deliver1.form.legal' />
        </a-form-model-item>

        <a-form-model-item label='营业执照号' prop='businessLicenseNo'>
          <a-input v-model='deliver1.form.businessLicenseNo' />
        </a-form-model-item>

        <a-form-model-item label='公司地址' prop='address'>
          <a-input v-model='deliver1.form.address' />
        </a-form-model-item>

        <a-form-model-item label='负责人' prop='masterMember'>
          <a-select show-search v-model='deliver1.form.masterMember'
                    :default-active-first-option='false'
                    :filter-option='false'
                    :allowClear='true'
                    placeholder="请选择"
                    @change="handleChange1"
                    @search='handleSearch1'>
            <a-select-option v-for='item in  presidentMerchantList' :key='item.id'>
              {{ item.masterName}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label='姓名' prop='virtualUserName'>
          <a-input v-model='deliver1.form.virtualUserName' />
        </a-form-model-item>

        <a-form-model-item label='虚拟号' prop='virtualNumber'>
          <a-input v-model='deliver1.form.virtualNumber' />
        </a-form-model-item>

        <a-form-model-item label='分公司成员' prop='memberListArr'>
          <a-select show-search v-model='deliver1.form.memberListArr'
                    :default-active-first-option='false'
                    :filter-option='false'
                    :allowClear='true'
                    mode="multiple"
                    placeholder="请选择"
                    @change="handleChange2"
                    @search='handleSearch2'>
            <a-select-option v-for='item in merchantList' :key='item.id'>
              {{ item.masterName||item.mobile  }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label='推荐分公司' prop='spreaderAreaId'>
          <a-select show-search v-model='deliver1.form.spreaderAreaId'
                    :default-active-first-option='false'
                    :filter-option='false'
                    :allowClear='true'
                    placeholder="请选择"
                    @change="handleChange3"
                    @search='handleChange3'>
            <a-select-option v-for='item in spreaderAreaList' :key='item.id'>
              {{ item.areaName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label='间推分公司' prop='indirectSpreaderAreaId'>
          <a-select show-search v-model='deliver1.form.indirectSpreaderAreaId'
                    :default-active-first-option='false'
                    :filter-option='false'
                    :allowClear='true'
                    placeholder="请选择"
                    @change="handleChange4"
                    @search='handleSearch4'>
            <a-select-option v-for='item in indirectSpreaderList' :key='item.id'>
              {{ item.areaName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label='服务赋能' prop='serviceMemberListShow'>
          <a-select show-search v-model='deliver1.form.serviceMemberListShow'
                    :default-active-first-option='false'
                    :filter-option='false'
                    :allowClear='true'
                    placeholder="请选择"
                    @change="handleChange5"
                    @search='handleSearch5'>
            <a-select-option v-for='item in merchantFuNengList' :key='item.id'>
              {{ item.masterName||item.mobile  }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import {ChartCard, Ellipsis, STable} from '@/components'
import {queryMerchant} from '@/api/member'
import {
  deletePerformanceArea,
  putPerformanceArea,
  putPerformancePermission,
  queryPerformanceArea
} from '@/api/performance'
import moment from 'moment'
import storage from '@/utils/storage.js';
import client from "@/config/oss.config";

const columns = [
  {
    width: 100,
    title: '所属品牌公司',
    dataIndex: 'brandName',
    scopedSlots: { customRender: 'brandName' }
  },
  // {
  //   width: 100,
  //   title: '分公司名称',
  //   dataIndex: 'areaName',
  //   scopedSlots: { customRender: 'areaName' }
  // },
  {
    width: 100,
    title: '分公司简称',
    dataIndex: 'areaShortName',
    scopedSlots: { customRender: 'areaShortName' }
  },
  {
    width: 100,
    title: '负责人',
    dataIndex: 'masterName',
    scopedSlots: { customRender: 'masterName' }
  },
  {
    width: 80,
    title: '积分使用',
    scopedSlots: { customRender: 'virtualNumberCashPermission' }
  },
  {
    width: 100,
    title: '虚拟号',
    dataIndex: 'virtualNumber',
    scopedSlots: { customRender: 'virtualNumber' }
  },
  {
    width: 80,
    title: '可用积分',
    dataIndex: 'virtualCashBalance',
    scopedSlots: { customRender: 'virtualCashBalance' }
  },
  {
    width: 100,
    title: '成员人数',
    dataIndex: 'memberCount',
    scopedSlots: { customRender: 'memberCount' }
  },
  {
    width: 400,
    title: '成员',
    dataIndex: 'memberNameDesc',
    scopedSlots: { customRender: 'memberNameDesc' }
  },
  {
    width: 200,
    title: '赋能成员',
    dataIndex: 'serviceMemberNameDesc',
    scopedSlots: { customRender: 'serviceMemberNameDesc' }
  },
  {
    width: 80,
    title: '总业绩',
    dataIndex: 'totalPerformance',
    scopedSlots: { customRender: 'totalPerformance' }
  },
  {
    width: 100,
    title: '推荐分公司',
    dataIndex: 'spreaderAreaName',
    scopedSlots: { customRender: 'spreaderAreaName' }
  },
  {
    width: 100,
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const monthFormatType = 'YYYYMM';

export default {
  name: 'PerformanceAreaList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      // 筛选表单
      monthFormatType,
      form: {
        month: moment().format(monthFormatType),
      },
      loading: false,
      dashboard: {}, //订单统计数据
      // 表头
      columns,
      ext1:0,//可用积分合计
      ext2:0,//总业绩合计
      // 加载数据方法 必须为 Promise 对象
      queryParam: {},
      loadData: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        let param = Object.assign(parameter, this.queryParam, this.form);
        return queryPerformanceArea(param).then(datum => {
          this.ext1 = datum.ext1;
          this.ext2 = datum.ext2;
            return datum
          })
      },
      merchantList: [],
      presidentMerchantList: [],
      spreaderAreaList:[],
      indirectSpreaderList:[],
      merchantFuNengList:[],
      deliver1: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {
          areaName: [{ required: true, message: '请输入分公司名称', trigger: 'change' }],
          areaShortName: [{ required: true, message: '请输入分公司简称', trigger: 'change' }],
          masterMember: [{ required: true, message: '请选择负责人', trigger: 'change' }],
          businessLicenseNo: [{ required: true, message: '请填写营业执照号', trigger: 'change' }],
          legal: [{ required: true, message: '请填写法人', trigger: 'change' }],
          address: [{ required: true, message: '请填写地址', trigger: 'change' }],
          virtualNumber: [
            { required: true, message: '请输入虚拟号', trigger: 'change' },
            { validator: (rule, value, callback) => {
                if (value.length<6) {
                  callback(new Error('虚拟号码不能少于6位数'));
                } else {
                  callback();
                }
              }, trigger: 'change' },
          ],
          memberListArr: [{ required: true, message: '请选择大区成员', trigger: 'change' }],
        },
      },
    }
  },
  created() {},
  methods: {
    moment,
    handleChange1(e) {
      this.$set(this.deliver1.form, "masterMember", e)
    },
    handleSearch1(keyword) {
      queryMerchant({ current: 1, size: 99, type: "president", keyword: keyword }).then(result => {
        this.presidentMerchantList = result.records;
        let masterMember = this.deliver1.form.masterMember;
        let masterName = this.deliver1.form.masterName;
        let len = this.presidentMerchantList.findIndex(item => item.id == masterMember);//是否存在
        // debugger;
        if (masterMember && masterName && len < 0) {
          this.presidentMerchantList.unshift({
            id: masterMember,
            masterName: this.deliver1.form.masterName,
          })
        }
      })
    },
    handleChange2(e) {
      this.$set(this.deliver1.form, "memberListArr", e)
    },
    handleSearch2(keyword) {
      queryMerchant({ current: 1, size: 99,president: "1", keyword: keyword  }).then(result => {

        this.merchantList = result.records;//查找所有商户
        let memberPerformanceList = this.deliver1.form.memberPerformanceList;
        if (memberPerformanceList&& memberPerformanceList.length > 0) {
          for (const item of memberPerformanceList) {
            if (this.merchantList.findIndex(value => item.member == value.id) == -1) {
              //不存在则添加到显示列表中去
              this.merchantList.unshift({
                id: item.member,
                masterName: item.memberMasterName,
              })
            }
          }
        }
      })
    },
    handleChange3(e) {
      this.$set(this.deliver1.form, "spreaderAreaId", e)
    },
    handleSearch3(record) {
      let params = { pageNum: 1, pageSize: 99,month:this.form.month  };
      if(record){
        //将自己和间推排除
        params.notEqId = record.id + "," + record.indirectSpreaderAreaId;
      }
      queryPerformanceArea(params).then(result => {
        this.spreaderAreaList = result.records;

            // if (this.spreaderAreaList.findIndex(value => item.member == value.id) == -1) {
            //   this.merchantList.unshift({
            //     id: item.member,
            //     masterName: item.memberMasterName,
            //   })
            // }

      })
    },
    handleChange4(e) {
      this.$set(this.deliver1.form, "indirectSpreaderAreaId", e)
    },
    handleSearch4(record) {
      let params = { pageNum: 1, pageSize: 99,month:this.form.month  };
      if(record){
        //将自己和直推排除
        params.notEqId = record.id + "," + record.spreaderAreaId;
      }
      queryPerformanceArea(params).then(result => {
        this.indirectSpreaderList = result.records;

      })
    },
    handleChange5(e) {
      this.$set(this.deliver1.form, "fuNengListArr", e)
    },
    handleSearch5(keyword) {
      queryMerchant({ current: 1, size: 99, president: "1", keyword: keyword }).then(result => {
        this.merchantFuNengList = result.records;
      })
    },

    handleReset() {
      this.form.month = moment().format(monthFormatType);
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    initialDeliver1(record) {
      this.deliver1.form = {};
      this.deliver1.loading = true
      this.handleSearch1()//负责人列表
      this.handleSearch2()//成员列表
      this.handleSearch3(record)//推荐分公司列表
      this.handleSearch4(record);//间推分公司列表
      this.handleSearch5()//成员列表
      // debugger
      if(record.id){
      //设置成员选中效果
          if (record && record.memberPerformanceList) {
            let arr = record.memberPerformanceList.map(item => item.member);
            record.memberListArr = arr;
          }

          //设置赋能成员选中效果
          // if (record && record.serviceMemberList) {
          //   let arr = record.serviceMemberList.map(item => item.member);
          //   record.fuNengListArr = arr;
          // }

          let params = Object.assign(record);
          // debugger
          let service = record.serviceMemberList;
          if(service && service.length>0){
            params.serviceMemberListShow = service[0].member;
          }

          this.deliver1.form = params;
      }
      this.deliver1.loading = false
      this.deliver1.visible = true
    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        if (result) {
          this.deliver1.loading = true;
          this.deliver1.form.memberList = this.deliver1.form.memberListArr.join(",");
          //赋能特殊处理
          if(this.deliver1.form.serviceMemberListShow && this.deliver1.form.serviceMemberListShow.length>0){
            this.deliver1.form.serviceMemberList = this.deliver1.form.serviceMemberListShow;
          }

          let params = Object.assign(this.deliver1.form);

          putPerformanceArea(params).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver1.visible = false
            this.deliver1.loading = false;
          }).catch(() => {
            this.deliver1.loading = false;
          })
        }
      })
    },
    handleEdit(record) {
      storage.set("performanceAreaEdit_" + record.id, record)
      this.$router.push({ path: '/performance/area/office/edit', query: { id: record.id } })
    },
    handleDelete(record) {
      deletePerformanceArea({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    async handleUpload(event, key) {
      this.loading = true
      let result = await client.put('avatar/' + event.file.uid, event.file)
      this.$set(this.deliver1.form, key, result.url)
      this.loading = false
      this.$refs.form.clearValidate()
    },
    isUserCash(record){
      let permission = record.virtualNumberCashPermission=='1'?'0':'1';
      // debugger
      let params = Object.assign({id:record.id,virtualNumberCashPermission:permission});

      putPerformancePermission(params).then(_result => {
        record.virtualNumberCashPermission = permission;
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
        this.deliver1.visible = false
        this.deliver1.loading = false;
      }).catch(() => {
        this.deliver1.loading = false;
      })

    }
  }
}
</script>
<style lang="less" scoped>
/deep/.total {
  font-size: 20px;
}
/deep/.ant-page-header{
  display: none;
}
</style>
